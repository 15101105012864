<template>
    <div>
        <p v-if="hintText">{{ $t(hintText) }}</p>
        <v-text-field
            :label='$t("fields.callsign.label")'
            type="text"
            v-model="answer"
            :rules="isRequired ? [rules.required, rules.min, rules.max] : []"
            name="callsign"
            counter="5"
            autocomplete="off"
            @blur="updateAnswer"
        ></v-text-field>
    </div>
</template>

<script>
import FieldTypes from '../../../mixins/FieldTypes';

export default {
    name: 'Callsign',
    props: {
        hintText: {
            default: 'fields.callsign.hint_text'
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            answer: '',
            rules: {
                required: value => !!value || this.$t('fields.general.rules.required'),
                min: v => (v && v.length > 4) || this.$t('fields.general.rules.exact_length', {length: 5}),
                max: v => (v && v.length < 6) || this.$t('fields.general.rules.exact_length', {length: 5})
            }
        };
    },
    mixins: [FieldTypes]
};
</script>
