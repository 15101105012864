<script>
import {mapState} from 'vuex';
export default {
    name: 'FieldTypes',
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers', 'surveyMode'])
    },
    methods: {
        updateAnswer() {
            this.$emit('updateAnswer', this.answer);
        },
        updateNumericAnswer() {
            this.$emit('updateAnswer', parseInt(this.answer));
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        this.answer = answer[this.questionId];
                    }
                });
            }
        }
    },
    created() {
        if (this.surveyMode === 'resume-survey') {
            this.checkForAnswer();
        }
    }
};
</script>
